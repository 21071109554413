@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Kanit&family=Press+Start+2P&display=swap");

body {
  margin: 0;
  font-family: "Press Start 2P", cursive;
  /* background-color: #e8908a; */
  /* background-image: url("assets/image/bg-pattern.png"); */
  background-repeat: round;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

p,
ul,
li {
  font-family: "Kanit", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

